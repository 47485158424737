<template>
  <page-content>

    <common-table
        ref="table"
        search-placeholder="搜索设备MAC"
        path="web/iot/device/authorization/page"
        :queryParams="queryParams"
        :columns="columns">

      <template slot="search">

        <div style="display: flex">
        <a-button @click="$refs.table.export('语音授权记录')">导出Excel</a-button>

          <div style="flex-grow: 1;"></div>

        <a-space>
          <div>手机号：</div>
          <a-input v-model="queryParams.userPhone" placeholder="搜索用户手机号" allow-clear></a-input>
        </a-space>

      </div>

      </template>

      <template slot="autoReport" slot-scope="{record}">
        <a-tag v-if="record.autoReport" color="gray">设备上报</a-tag>
        <a-tag v-else color="cyan">手动上报</a-tag>
      </template>

    </common-table>


  </page-content>
</template>
<script>
export default {

  data() {
    return {
      getListInterval: null,
      queryParams: {
        userPhone:null
      },
      vendorList:[]
    }
  },

  created() {
    this.getListInterval = setInterval(() => {
      this.getList()
    }, 30000)
    this.getVendorList()
  },
  destroyed() {
    clearInterval(this.getListInterval)
  },

  computed: {
    columns() {
      let vendorFilter = [{text: '全部', value: ''}]
      this.vendorList.forEach(item=>{
        vendorFilter.push({text: item.name, value: item.id})
      })
      return [
        {
          title: '用户昵称',
          dataIndex: 'userName',
        },
        {
          title: '用户手机号',
          dataIndex: 'userPhone',
        },
        {
          title: '手机编号',
          dataIndex: 'phoneId',
        },
        {
          title: '授权类型',
          dataIndex: 'type',
          customRender(text,record){
              if(text==2){
                return "消耗设备额度"
              }else if(text==3){
                return "支付授权"
              }else{
                return text
              }
            },
            filters: [
              {text: '全部', value: ''},
              {text: '消耗设备额度', value: 2},
              {text: '支付授权', value: 3},
            ],
            filterMultiple: false,
        },
        {
          title: '设备MAC',
          dataIndex: 'name',
        },
        {
          title: '时间',
          dataIndex: 'createdAt',
          sorter: true
        },
      ]
    }
  },
  methods: {
    getList() {
      this.$refs.table.getData()
    },
    getVendorList(){
      this.$get('vendor?pageSize=100').then((r) => {
        let data = r.data
        this.vendorList = data.rows
      })
    }

  }
}
</script>
<style lang="less" scoped>

</style>
